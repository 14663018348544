import React from 'react';
import styled from 'styled-components';
import { Mode, IMode, IPressureWithType, IInput, IBloodPressureInput } from './types';
import { Answer } from './Intro';
import { Note } from './Note';
import { Pressures, Links } from './../services/bloodPressure';
import TextInput from './form/TextInput';

const InputDiv = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 20px;
  line-height: 1.35em;

  .lbl {
    width: 40%;
    padding-top: 8px;
    font-size: 1.2em;
    line-height: 1.25em;
  }
  .in {
    flex: 1;
    margin-bottom:5px;
    input {
      width: 90%;
      font-size: 3.5em;
      font-weight: bold;
      padding: 12px 16px;
      border: 1px #0099ff solid;
      border-radius: 10px;
      color: #585858;
      outline: none !important;
    }
  }

  .tooltip {
    width: 22px;
    height: 22px;
    position: relative;
    background: url(icons/i.png) no-repeat;
  }
  .tooltip .arr {
    display: none;
    position: absolute;
    z-index: 2;
    left: 22px;
    top: 8px;
    width: 9px;
    height: 10px;
    background: url(icons/left-of-the-tip.png) no-repeat;
  }
  .tooltip .content {
    display: none;
    width: 150px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    left: 30px;
    top: 0px;
    border-radius: 5px;
    background: #e20886;
    color: white !important;
    font-size: 0.7em;
    font-weight: bold;
    line-height: 1.2em;
  }
  .tooltip:hover .content {
    display: block;
  }
  .tooltip:hover .arr {
    display: block;
  }
`;

export const InputPressure = (props: IInput) => {
  const { title, tooltip, value, set, placeholder } = props;
  return (
    <InputDiv>
      <div className='lbl'>
        <div>{title}<br />blood pressure</div>
        <div className='tooltip'>
          <div className='content'>{tooltip}</div>
          <div className='arr'></div>
        </div>
      </div>
      <div className='in'>
        <TextInput maxLength={3} onChange={set} value={value || ''} placeholder={placeholder} />
      </div>
    </InputDiv>
  );
}

export const BloodPressureInput = (props: IBloodPressureInput) => {
  const tipS = 'Systolic blood pressure is the pressure when your heart pushes blood out';
  const systolic: IInput = { placeholder: '120', title: 'Systolic', value: props.s, set: props.setS, tooltip: tipS }
  const tipD = 'Diastolic blood pressure is the pressure when your heart rests between beats';
  const diastolic: IInput = { placeholder: '90', title: 'Diastolic', value: props.d, set: props.setD, tooltip: tipD }
  const canSubmit = props.s && props.d && props.s >= 40 && props.d >= 40;
  return (
    <div className="input">
      <div className='title'>Find out what your blood pressure means</div>
      <InputPressure {...systolic} />
      <InputPressure {...diastolic} />
      <div className='panel-right' style={{ paddingTop: 20 }}>
        <button
          className='btn btn-primary'
          disabled={!canSubmit}
          onClick={() => props.setMode(Mode.Chart)}
        >
          Submit blood pressure
        </button>
      </div>
      <div className='panel-right' style={{ paddingTop: 40 }}>
        <button className='link' onClick={() => props.setMode(Mode.ChartHover)}>
          I don't know my blood pressure
        </button>
      </div>
    </div>
  )
}

const NextSteps: React.FC = () => (
  <div className='next-steps'>
    <h4>Next steps</h4>
    <p>Consult your GP to maintain a healthy level of blood pressure.</p>
    <p>
      You can also read our article
      on <a href={Links.High}>how to reduce high blood pressure</a>.
    </p>
  </div>
)


const NextStepsLow: React.FC = () => (
  <div className='next-steps'>
    <h4>Next steps</h4>
    <p>Consult your GP if you, or someone close to you, continuously gets symptoms of dizziness and nausea as these may be a sign of hypotension.</p>
    <p>
      You can also read our article
      on <a href={Links.Low}>how to manage low blood pressure</a>.
    </p>
  </div>
)

export const BloodPressureDetails = (props: IPressureWithType) => {
  const { type, s, d, mode, setMode } = props;
  const found = Pressures.find(p => (p.t === type))
  if (!found) return null;
  const isHovered = (s === 0 && d === 0);
  const isSelected = (!isHovered);
  const propsMode: IMode = { mode, setMode }
  const Above: React.FC = () => (
    <div>
      <div className='above'>
        {(!isHovered) ? `${s}/${d}mmHg` : found.range}
      </div>
      <h2>{found.title}</h2>
    </div>
  );
  const Down: React.FC = (props: any) => (
    <div>
      {(isHovered) ? (
        <p>
          {props.children}
        </p>
      ) : (
        <p>
            Your blood pressure of {s}/{d}mmHg is within the hypertensive range
            and has the potential to increase the risk of suffering from a stroke or a heart attack*.
            So it's important to try and get your blood pressure down.
            The good news is it’s one of the most preventable and treatable conditions.
        </p>
      )}
      <h4>Potential causes</h4>
      <ul>
        <li>Being overweight</li>
        <li>Regularly drinking alcohol beyond the low-risk guidelines</li>
        <li>Not doing enough exercise</li>
        <li>A family history of high blood pressure</li>
        <li>Consuming too much <a href={Links.Salt}>salt</a></li>
      </ul>
      <NextSteps />
    </div>
  );
  if (type === 'Low') return (
    <div className="details">
      <Above />
      {(isHovered) ? (
        <p>
          This is a lower than normal reading, but it isn’t of high concern*.
          We recommend seeing a GP if someone continuously gets
          symptoms of dizziness or nausea, as this may be a sign of hypotension.
        </p>
      ) : (
        <p>
          Your blood pressure of {s}/{d}mmHg is quite low, but not of high concern*.
          However, we recommend seeing your GP if you continuously get symptoms
          like dizziness or nausea, as these may be a sign of hypotension.
        </p>
      )}
      <h4>Potential causes</h4>
      <ul>
        <li>Dehydration</li>
        <li>Lack of healthy diet and exercise</li>
        <li>A family history of low blood pressure</li>
        <li>Certain medication such as beta-blockers, diuretics, anti-depressants</li>
        <li>Blood loss due to injury/internal bleeding</li>
      </ul>
      <NextStepsLow />
      {!isSelected ? <Answer {...propsMode} />: false}
      <Note />
    </div>
  );
  if (type === 'Normal') return (
    <div className="details">
      <Above />
      {(isHovered) ? (
        <p>
          Little to no risk of serious complications arising such as a stroke or heart attack*.
          It’s still important to maintain this level of blood pressure
          to prevent the risks of high blood pressure.
        </p>
      ) : (
        <p>
          Your blood pressure of {s}/{d}mmHg is normal which means there
          is little to no risk of serious complications arising such as a stroke or heart attack*.
          It’s still important to maintain this level of blood pressure to prevent it from getting higher.
        </p>
      )}
      <h4>How to maintain normal blood pressure</h4>
      <ul>
        <li>Exercise regularly</li>
        <li>Maintain a healthy weight </li>
        <li>Don't consume alcohol over recommended levels</li>
        <li>Don't consume excess <a href={Links.Salt}>salt</a></li>
      </ul>
      {!isSelected ? <Answer {...propsMode} />: false}
      <Note />
    </div>
  );
  if (type === 'Pre Hypertension') return (
    <div className="details">
      <Above />

      {(isHovered) ? (
        <p>Although in many cases, pre-hypertension is completely normal, this pressure may increase the risk of suffering from a stroke or heart attack*. So it’s important to try and get your blood pressure down.</p>
      ) : (
        <p>
          Your blood pressure of {s}/{d}mmHg is within the hypertensive range.
          Although in many cases, pre-hypertension is completely normal, this pressure may increase the risk of suffering from a stroke or heart attack*.
          So it's important to try and get your blood pressure down.
          The good news is it’s one of the most preventable and treatable conditions.
        </p>
      )}
      <h4>Potential causes</h4>
      <ul>
        <li>Being overweight</li>
        <li>Regularly drinking alcohol beyond the low-risk guidelines</li>
        <li>Not doing enough exercise</li>
        <li>A family history of high blood pressure</li>
        <li>Consuming too much <a href={Links.Salt}>salt</a></li>
      </ul>
      <NextSteps />
      {!isSelected ? <Answer {...propsMode} />: false}
      <Note />
    </div>
  );
  if (type === 'High Stage 1') return (
    <div className="details">
      <Above />
      <Down>May increase the risk of suffering from a stroke or heart attack*. So it's extremely important to get your blood pressure down.</Down>
      {!isSelected ? <Answer {...propsMode} />: false}
      <Note />
    </div>
  );
  if (type === 'High Stage 2') return (
    <div className="details">
      <Above />
      <Down>May increase the risk of suffering from a stroke or heart attack*. So it's extremely important to get your blood pressure down. </Down>
      {!isSelected ? <Answer {...propsMode} />: false}
      <Note />
    </div>
  );
  return null;
}
