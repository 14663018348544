import React from 'react';
import styled from 'styled-components';
import { Checkbox } from './form/Checkbox';
import { Mode, IStringValue, ILifestyleInput } from './types';
import { LifeStyleQuestions, Links } from '../services/bloodPressure';
import { Note } from './Note';

const Item = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  .explained { font-size: .75em; margin-left: 1cm; color: #888; }
`;

export const LifestyleInput: React.FC<any> = (props: ILifestyleInput) => {
    const { lifestyle, setLifestyle, mode, setMode } = props;
    
    const onCalculate = () => { setMode(Mode.Answers); }
    return (
        <div className='questions'>
            <div className='title sm'>Tick the boxes that apply to you</div>
            {LifeStyleQuestions.map(question => {
                const { id, q, explained } = question;
                const value = (lifestyle.indexOf(id) !== -1);
                const toggle = () => {
                    if (lifestyle.indexOf(id) !== -1) {
                        setLifestyle(lifestyle.filter(e => (e !== id)));
                    } else {
                        setLifestyle([...lifestyle, id]);
                    }
                    setMode(Mode.Lifestyle); // if anything was touched - reset the mode
                }
                return (
                    <Item key={id}>
                        <Checkbox className='nice' label={q} value={value} onChange={toggle} />
                        {(explained) ? (<div className='explained'>{explained}</div>) : false}
                    </Item>
                )
            })}
            <div className='panel-centered' style={{ paddingTop: 40 }}>
                <button
                    className='btn btn-primary'
                    disabled={(mode !== Mode.Lifestyle) || (lifestyle.length === 0)} 
                    onClick={onCalculate}
                >
                    Show results
                </button>
            </div>
        </div>
    );
}

const Hdr = styled.div`
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 25px;
`;

const PleaseConsult: React.FC = () => (
    <p><strong>Please consult your GP practice nurse or pharmacist who can check your blood pressure.</strong></p>
);

const YouCanAlsoRead = styled.p`
    margin-top: 30px;
`;

export const LifestyleFindOut: React.FC<IStringValue> = (props: IStringValue) => {
    const { value } = props;
    return (
        <div>
            {(value) ? false : (
                <Hdr>Find out how your lifestyle may affect your blood pressure*</Hdr>
            )}
            {(value === 'Low') ? (
                <div>
                    <Hdr>You may be at risk of low blood pressure*</Hdr>
                    <PleaseConsult />
                    <YouCanAlsoRead>
                        You can also read our article on&nbsp;
                        <a href={Links.Low}>how 
                        to increase low blood pressure</a>
                    </YouCanAlsoRead>
                </div>
            ): false}
            {(value === 'High') ? (
                <div>
                    <Hdr>You may be at risk of high blood pressure*</Hdr>
                    <PleaseConsult />
                    <YouCanAlsoRead>
                        You can also read our article on&nbsp;
                        <a href={Links.High}>how 
                        to reduce high blood pressure</a>
                    </YouCanAlsoRead>
                </div>
            ): false}
            {(value === 'High/Low') ? (
                <div>
                    <Hdr>
                        You have selected a range of lifestyle factors. 
                        Some of these are associated with low blood pressure 
                        and some with high*. 
                    </Hdr>
                    <PleaseConsult />
                    <YouCanAlsoRead>
                        You can also read our article on&nbsp;
                        <a 
                            href={Links.Low}>how to increase low blood pressure</a> or <a 
                            href={Links.High}>how to reduce high blood pressure</a>
                    </YouCanAlsoRead>
                </div>
            ): false}
            <Note />
        </div>
    )
}

