import React from 'react';
import styled from 'styled-components';
import { IColor, ISize, IPressureWithType } from './types';
import { Pressures } from './../services/bloodPressure';
import ContainerDimensions from 'react-container-dimensions'


const LabelY = styled.div`
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  text-orientation: mixed;
  text-align: center;
  font-weight: bold;
  height: ${(props: ISize) => props.size}px;
`
const LabelX = styled.div`
  text-align: center;
  font-weight: bold;
  width: ${(props: ISize) => props.size}px;
  margin-left: 30px;
`

const ChartWrapper = styled.div`
  position: relative;
  margin-bottom: 50px;
`

const PressureDiv = styled.div`
  position: absolute;
  border: 3px white solid;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;

  @media (max-width: 1096px) { font-size: 0.75em; padding-top: 1px; }
  @media (max-width: 840px) { font-size: 0.66em; padding-top: 0px; }
  @media (max-width: 760px) { font-size: 0.75em; padding-top: 1px; }
  @media (max-width: 520px) { font-size: 0.66em; padding-top: 0px; }

  &.clickable { cursor: pointer; }
  &.inactive { background: #e6e6e6 !important; color: white !important; }
  &.interactive { background: #e6e6e6; color: white !important; }
  &.interactive:hover { background: ${(props:IColor) => props.color} !important; color: white !important; }
  &.active { background: ${(props:IColor) => props.color} !important; color: white !important; }
`

const LabelLeft = styled.div`
  position: absolute;
  z-index: 20;
  left: 15px;
  width: 30px;
  text-align: right;
  font-size: 0.85em;
`
const DashLeft = styled.div`
  position: absolute;
  z-index: 21;
  width: 5px;
  height: 1px;
  background: #565656;
  border: none;
`;

const DashRight = styled.div`
  position: absolute;
  z-index: 21;
  width: 1px;
  height: 5px;
  background: #565656;
  border: none;
`;


const LabelBottom = styled.div`
  z-index: 20;
  position: absolute;
  text-align: center;
  width: 50px;
  font-size: 0.85em;
`

export const BloodPressureChart = (props: IPressureWithType) => {
    const { type } = props;
    const found = Pressures.find(p => type === p.t)
    // const onMouseOut = () => {
    //   if (typeof props['onHover'] === 'function') props['onHover']('');
    // }
    return (
      <div className="input">
        <div className='title'>
          {/*!found ? false : (
            <div style={{ position: 'absolute', right: 0, top: 15 }}>
                <img alt='' src='icons/arrow-down.png' />
            </div>
          )*/}
          {(found) ? found.chartTitle : 'Click on graph for more information' }
          {(found && !found.chartTitle) ? <div><br /><br /></div> : false}
        </div>

        <ContainerDimensions>
            { ({ width }) => {
              const chartSize = width - 75;
              const maxS = 180;
              const maxD = 120;
              return (
                <ChartWrapper style={{ width, height: width - 30 }}>
                  <LabelY {...{ size: width - 30}}>Systolic Blood Pressure</LabelY>
                  <LabelX {...{ size: width - 30}}>Diastolic Blood Pressure</LabelX>
                  {Pressures.map(p => {
                    const { t, s, d, short, color, zI } = p
                    const cls = [`pressure-${t}`]
                    const onMouseClick = () => {
                      if (typeof props['onHover'] === 'function') {
                        props['onHover'](t);
                      }
                    }

                    if (!type) { cls.push('interactive'); cls.push('clickable'); }
                    else if (t === type) cls.push('active');
                    else cls.push('inactive');

                    const sTitle = `${s}${(s === maxS) ? '+' : ''}`
                    const dTitle = `${d}${(d === maxD) ? '+' : ''}`

                    const w = (s * chartSize / maxS);
                    const h = (d * chartSize / maxD);
                    const style = {
                      zIndex: zI,
                      left: 60,
                      top: chartSize - h,
                      width: w + 'px',
                      height: h + 'px',
                    }
                    return [
                      <PressureDiv key={t} style={style} color={color} onClick={onMouseClick} className={cls.join(' ')}>
                        {short}
                      </PressureDiv>,

                      <DashLeft key={`${t}-dl`} style={{ top: chartSize - h + 1, left: 58 }} />,
                      <LabelLeft key={`${t}-ll`} style={{ top: chartSize - h - 5, left: 20 }}>{sTitle}</LabelLeft>,

                      <DashRight key={`${t}-dr`} style={{ top: chartSize + 6, left: 73 + w }} />,
                      <LabelBottom key={`${t}-lb`} style={{ top: chartSize + 15, left: 45 + w }}>{dTitle}</LabelBottom>
                    ]
                  })}
                </ChartWrapper>
              )
            }}
        </ContainerDimensions>
      </div>
    );
  }

