export interface ISize {
    size: number
}

export interface IColor {
    color: string
}

export enum Mode {
    Input = "",
    Chart = "chart",
    ChartHover = "charthover",
    Lifestyle = "lifestyle",
    Answers = "answers",
}

export interface IMode {
    mode: Mode
    setMode: (arg0: Mode) => void
}

export interface IInput {
    value: number|string
    set: Function
    title: string
    tooltip?: string
    placeholder?: string
}

export interface IBloodPressureInput {
    s: number
    setS: Function
    d: number
    setD: Function
    mode: string
    setMode: Function
    error: string | null
    setError: Function
}

export interface IBloodPressureValues {
    s: number
    d: number
}

export interface ILifestyle {
    lifestyle: Array<string>
}

export interface IStringValue {
    value: string
}

export interface ILifestyleInput {
    lifestyle: Array<string>
    setLifestyle: Function
    mode: string
    setMode: (arg0: Mode) => void
}

export interface ISetter {
    value: string
    set: Function
}

export interface IPressureWithType {
    type: string
    hType?: string
    s: number
    d: number
    onHover?: (arg0: string) => void
    mode: Mode    
    setMode: (arg0: Mode) => void
}