import { IPressureType, IPressureRangeDescriptor, ILifeStyleQuestion } from './types';

// const potentialToIncrease = 'Potential to increase the risk of suffering from a stroke or heart attack';
const potentialToIncrease = 'Potential to increase the risk of serious implications';
const atRiskHyper = 'You may be at risk of hypertension';
export const Pressures: Array<IPressureType> = [
    {
        t: 'Low', color: '#1e9d8b', s: 90, d: 60, zI: 10,
        title: 'Risk of hypotension',
        short: 'Hypotension (low blood pressure)',
        chartTitle: 'You may be at risk of hypotension',
        range: 'Lower than 90/60mmHg'
    },
    {
        t: 'Normal', color: '#40a640', s: 120, d: 80, zI: 9,
        short: 'Normal',
        // chartTitle: 'Little to no risk of serious complications arising such as a stroke or heart attack',
        // chartTitle: 'Little to no risk of hypotension/hypertension',
        chartTitle: 'Little to no risk of serious implications',
        title: 'Your blood pressure reading is normal',
        range: '91/61mmHg to 120/80mmHg'
    },
    {
        t: 'Pre Hypertension', color: '#ef700f', s: 140, d: 90, zI: 8,
        short: 'Pre Hypertension',
        chartTitle: atRiskHyper,
        title: potentialToIncrease,
        range: '121/81mmHg to 140/90mmHg'
    },
    {
        t: 'High Stage 1', color: '#f28cc6', s: 160, d: 100, zI: 7,
        short: 'High: Stage 1 Hypertension',
        chartTitle: atRiskHyper,
        title: potentialToIncrease,
        range: '141/91mmHg to 160/100mmHg'
    },
    {
        t: 'High Stage 2', color: '#ef6bb7', s: 180, d: 120, zI: 6,
        short: 'High: Stage 2 Hypertension',
        chartTitle: atRiskHyper,
        title: potentialToIncrease,
        range: '161/101mmHg to 180/120mmHg'
    }
];

const PressureType: Array<IPressureRangeDescriptor> = [
    { minS: 40, maxS: 90, minD: 40, maxD: 60, t: 'Low' },
    { minS: 40, maxS: 90, minD: 61, maxD: 80, t: 'Normal' },
    { minS: 40, maxS: 90, minD: 81, maxD: 90, t: 'Pre Hypertension' },
    { minS: 40, maxS: 90, minD: 91, maxD: 100, t: 'High Stage 1' },
    { minS: 40, maxS: 90, minD: 101, t: 'High Stage 2' },
    { minS: 91, maxS: 120, minD: 40, maxD: 60, t: 'Normal' },
    { minS: 91, maxS: 120, minD: 61, maxD: 80, t: 'Normal' },
    { minS: 91, maxS: 120, minD: 81, maxD: 90, t: 'Pre Hypertension' },
    { minS: 91, maxS: 120, minD: 91, maxD: 100, t: 'High Stage 1' },
    { minS: 91, maxS: 120, minD: 101, t: 'High Stage 2' },
    { minS: 121, maxS: 140, minD: 40, maxD: 60, t: 'Pre Hypertension' },
    { minS: 121, maxS: 140, minD: 61, maxD: 80, t: 'Pre Hypertension' },
    { minS: 121, maxS: 140, minD: 81, maxD: 90, t: 'Pre Hypertension' },
    { minS: 121, maxS: 140, minD: 91, maxD: 100, t: 'High Stage 1' },
    { minS: 121, maxS: 140, minD: 101, t: 'High Stage 2' },
    { minS: 141, maxS: 160, minD: 40, maxD: 60, t: 'High Stage 1' },
    { minS: 141, maxS: 160, minD: 61, maxD: 80, t: 'High Stage 1' },
    { minS: 141, maxS: 160, minD: 81, maxD: 90, t: 'High Stage 1' },
    { minS: 141, maxS: 160, minD: 91, maxD: 100, t: 'High Stage 1' },
    { minS: 141, maxS: 160, minD: 101, t: 'High Stage 2' },
    { minS: 161, minD: 40, maxD: 60, t: 'High Stage 2' },
    { minS: 161, minD: 61, maxD: 80, t: 'High Stage 2' },
    { minS: 161, minD: 81, maxD: 90, t: 'High Stage 2' },
    { minS: 161, minD: 91, maxD: 100, t: 'High Stage 2' },
    { minS: 161, minD: 101, t: 'High Stage 2' },
];

export const getBloodPressureType = (systolic: number, diastolic: number): string => {
    const found: IPressureRangeDescriptor|undefined = PressureType.find(p => {
        // console.log("s=", systolic, "d=", diastolic, "p=", JSON.stringify(p))
        const fitS: boolean = (!p.maxS || systolic <= p.maxS);
        const fitD: boolean = (!p.maxD || diastolic <= p.maxD);
        return (systolic >= p.minS && diastolic >= p.minD && fitS && fitD);
    })
    return (found) ? found.t : '';
}

export const LifeStyleQuestions: Array<ILifeStyleQuestion> = [
    { id: 'stressed', q: 'Regularly stressed', r: 'High/Low' },
    { id: 'smoking', q: 'Smoking', r: 'High' },
    { id: 'highhistory', q: 'Family history of high blood pressure', r: 'High' },
    { id: 'lowhistory', q: 'Family history of low blood pressure', r: 'Low' },
    { id: 'salt', q: 'Consumption of excess salt', r: 'High', explained: '(more than 1 teaspoon a day)' },
    {
        id: 'activity', q: 'Lack of physical activity', r: 'High/Low',
        explained: '(less than 150 minutes of moderate activity per week)'
    },
    {
        id: 'overweight', q: 'Overweight/obesity', r: 'High',
        explained: '(if BMI is higher than 25 and/or waist size larger than 94cm for men and 80cm for women)'
    },
    {
        id: 'alcohol', q: 'High consumption of alcohol', r: 'High',
        explained: '(higher than 6 pints of average-strength beer or 10 small glasses of low-strength wine every week)'
    },
];

export const getLifeStyleResult = (ids: Array<string>): string => {
    const t:Array<string> = [];
    ids.forEach(id => {
        const found = LifeStyleQuestions.find(p => id === p.id);
        if (found && t.indexOf(found.r) === -1) t.push(found.r);
    })
    if (t.length === 0) return '';
    if (t.length === 3) return 'High/Low';
    t.sort();
    if (t.length === 2) {
        if (t[0] === 'High' && t[1] === 'Low') return 'High/Low';
        if (t[0] === 'High' && t[1] === 'High/Low') return 'High';
        if (t[0] === 'High/Low' && t[1] === 'Low') return 'Low';
    }
    return t[0];
}

export const Links = {
    Salt: 'https://www.benenden.co.uk/be-healthy/nutrition/why-and-how-should-i-reduce-the-salt-in-my-diet/',
    High: 'https://www.benenden.co.uk/be-healthy/body/what-is-high-blood-pressure/',
    Low: 'https://www.benenden.co.uk/be-healthy/body/low-blood-pressure-symptoms/',
};
