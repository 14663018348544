import React from 'react';
import styled from 'styled-components'
import { Mode, IMode } from './types';

const Bullet = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    font-size: 1.1em;
    line-height: 1.6em;

    .bullet-pad { min-width: 85px; max-width: 85px; }
    @media(max-width:762px) {
        p.text {
            margin-bottom: 0px !important;
            font-size: 0.85em;
            line-height: 1.5em;
        }
    }
`;

export const Intro: React.FC<IMode> = (props: IMode) => {
  return (
    <div className="intro">
        <Bullet className='bullet'>
            <div className='bullet-pad'>
                <img src='icons/icon1.png' alt='' />
            </div>
            <p className='text'>
                More than <strong>15 million adults<sup>1</sup></strong> in the UK
                have high blood pressure
            </p>
        </Bullet>
        <Bullet className='bullet'>
            <div className='bullet-pad'>
                <img src='icons/icon2.png' alt='' />
            </div>
            <p className='text'>
                Enter your blood pressure to find out
                what your numbers mean*
            </p>
        </Bullet>
        <Bullet className='bullet'>
            <div className='bullet-pad'>
                <img src='icons/icon3.png' alt='' />
            </div>
            <p className='text'>
                Normal blood pressure is considered to be between&nbsp;
                <strong>90/60mmHg</strong> and <strong>120/90mmHg</strong>
            </p>
        </Bullet>
        <Bullet className='bullet'>
            <div className='bullet-pad'>&nbsp;</div>
            <p className='text'>
                <button className='link' onClick={() => props.setMode(Mode.ChartHover)}>
                I don't know my blood pressure
                </button>
            </p>
        </Bullet>

        <div className="foot">
            <p><sup>1</sup>GOV.UK, "Health matters: combating high blood pressure", 2017.</p>
            <p>*Please remember that <strong>this is a guide</strong>. If 
            you think you’re suffering from any changes to your blood pressure, 
            please consult a GP before making any diet and lifestyle changes</p>
        </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */ 
/* eslint-disable no-script-url */
export const Answer = (props: IMode) => (
    <p>
        Answer these questions to find out what your lifestyle 
        means <a className="link" href="javascript:void(0)"
            onClick={() => props.setMode(Mode.Lifestyle)}>for your blood pressure</a>.
    </p>
);

export const FindOutIntro:  React.FC<IMode> = (props: IMode) => { 
    return (
        <div className="intro">
            <p>
                <strong>
                    Having your blood pressure taken is the only way to find out if
                    your blood pressure is too high or too low because
                    most people won't have any obvious symptoms &ndash;
                    that's why it's called the silent killer. 
                    Having a test is easy and could save your life. 
                    Either book an appointment to see your GP or buy an at-home test kit from your local pharmacy.
                </strong>
            </p>
            <p>
                In the UK, high blood pressure is the&nbsp;
                <strong>third biggest risk factor</strong> for disease.
            </p>
            <p>
                Over five million adults in UK<sup>2</sup> suffer from undiagnosed high blood pressure.
            </p>
            <p>
                The good news is high blood pressure is one of the most&nbsp;
                <strong>preventable and treatable conditions</strong>.
            </p>
            <Answer {...props}/>
            <div className="foot">
                <p>
                    <sup>2</sup> Telegraph, 'Undiagnosed high blood pressure a 
                    ticking time bomb, says experts', 2019.
                </p>
            </div>
        </div>
    );
};