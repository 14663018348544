import React from 'react';
import './App.css';
import { BloodPressure, Storybook } from './components/BloodPressure';
import { Debounced } from './services/debounced';

declare global {
  interface Window { 
    Storybook: number
  }
}
window.Storybook = window.Storybook || 0;

const notifyResize = () => {
  if (typeof window === 'object' && window.parent) {
    const body = window.document.querySelector("body")
    const origin = window.location.href
    if (body !== null) {
      const height =  body.clientHeight + 40;
      window.parent.postMessage({ origin, height }, '*');
    }
  }
}

window.addEventListener('resize', () => {
  Debounced.start('resize', notifyResize, 250);
});
setTimeout(notifyResize, 50)

const App: React.FC = () => {
  if (process.env.REACT_APP_STORYBOOK === '1' || window.Storybook === 1) {
    return (<Storybook className='App' />);
  } 
  return <BloodPressure className='App' />
}

export default App;
