/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Checkbox.css';

export class Checkbox extends Component {
    static defaultProps = {
        className: ''
    };
    componentDidMount() {
        this.checked = this.props.value ? this.props.value : '';
        this.addClassName(this.checked ? 'fa-check' : '');
    }
    componentDidUpdate() {
        this.checked = this.props.value;
        this.addClassName(this.checked ? 'fa-check' : '');
    }
    addClassName = (className) => {
        let resultClassName = 'fa';
        if (className) {
            resultClassName = `${resultClassName} ${className}`;
        }
        this.button.className = resultClassName;
    };
    onChange = () => {
        this.checked = !this.checked;
        this.props.onChange(this.checked);
        this.addClassName(this.checked ? 'fa-check' : '');
    };
    render() {
        return (
            <div className='checkbox'>
                <button
                    type='checkbox'
                    onClick={this.onChange}
                    disabled={this.props.disabled || false}
                    ref={(button) => { this.button = button; }}
                />
                {this.props.label ? (
                    <span onClick={!this.props.disabled && this.onChange}>{this.props.label}</span> // eslint-disable-line
                ) : ''}
            </div>
        );
    }
}

Checkbox.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default Checkbox;
