import React from 'react';
import styled from 'styled-components';
import { IOnClick } from './types';

const BackButton = styled.div`
    position: relative;
    text-align: right;
    padding-top: 30px;
    margin-bottom: 30px;
    button {
        border: none;
        cursor: pointer;
        outline: none !important;
        width: 50px;
        height: 30px;
        background: url(icons/arrow-back.png) no-repeat;
    }
`

export const Back: React.FC<IOnClick> = (props: IOnClick) => {
    return (
        <BackButton>
            <button onClick={() => (props.onClick())}></button>
        </BackButton>
    );
};