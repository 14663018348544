import React from 'react';
import PropTypes from 'prop-types';

class TextInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };
  state = {};

  componentDidMount() {
    this.input.value = typeof this.props.value !== 'undefined' ? this.props.value : '';
  }
  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && typeof this.props.value !== 'undefined') {
      if (!this.isFocused) {
        this.input.value = this.props.value;
      } else if (this.props.max && this.props.max > 0) {
        if (parseInt(this.props.value, 10) <= parseInt(this.props.max, 10)) this.input.value = this.props.value;
        else this.input.value = this.props.max;
      }

      if (this.props.required && typeof this.props.value !== 'undefined' &&
        !this.props.value.trim()) this.state.valid = false; // eslint-disable-line
      else if (this.props.required) this.state.valid = true; // eslint-disable-line
    } else if (typeof this.props.value === 'undefined') {
      this.input.value = '';
    }
  }
  onFocus = () => {
    this.isFocused = true;
  };
  onBlur = () => {
    this.isFocused = false;
  };
  handleKeyPress = (e) => {
    if (e.which === 13) { console.warn(e); }
    if (e.shiftKey && e.which === 13 && typeof this.props.onCtrlEnter === 'function') {
      this.props.onCtrlEnter();
    } else if (e.which === 13 && typeof this.props.onEnter === 'function') {
      this.props.onEnter();
    }
    if (e.which === 27 && typeof this.props.onEsc === 'function') {
      this.props.onEsc();
    }
  };
  render() {
    const onChange = (e) => {
      let value = e.target.value;

      if (this.props.replace) {
        value = this.props.replace(value);
      }

      if (this.props.field) this.props.onChange(this.props.field, value);
      else this.props.onChange(value);

      if (this.props.replace) this.input.value = value;
    };
    const safeValue = this.props.value ? this.props.value : '';
    const others = {
      disabled: this.props.disabled ? this.props.disabled : false
    };
    return (
      <input
        maxLength={this.props.maxLength}
        ref={(input) => { this.input = input; }}
        onChange={onChange}
        onKeyPress={this.handleKeyPress}
        placeholder={this.props.placeholder || this.props.label || ''}
        className={`${this.props.className ? this.props.className : ''}`}
        onFocus={this.onFocus} onBlur={this.onBlur}
        defaultValue={safeValue}
        style={this.props.style}
        autoComplete='off'
        {...others}
      />
    );
  }
}

export default TextInput;

