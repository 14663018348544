import React from 'react';
import { Mode, IMode, ILifestyleInput, IBloodPressureInput, IPressureWithType } from './types';
import { BloodPressureDetails, BloodPressureInput } from './InputPressure';
import { LifestyleInput, LifestyleFindOut } from './Lifestyle';
import { Intro, FindOutIntro } from './Intro';
import { getLifeStyleResult, getBloodPressureType } from './../services/bloodPressure';
import { BloodPressureChart } from './Chart';
import { Back } from './form/Back';

const Lbox: React.FC<any> = (props: any) => (
  <div className="left"><div className="box">{props.children}</div></div>
);
const Rbox: React.FC<any> = (props: any) => (
  <div className="right"><div className="box">{props.children}</div></div>
);


export const Storybook: React.FC<any> = () => {
  const [lifestyle, setLifestyle] = React.useState([])
  const [mode, setMode] = React.useState(Mode.Input)
  const [error, setError] = React.useState('')
  const [hType, setHover] = React.useState('')
  const [s, setS] = React.useState(0)
  const [d, setD] = React.useState(0)
  const inputLifeStyle: ILifestyleInput = { lifestyle, setLifestyle, mode, setMode }
  const inputBloodPressure: IBloodPressureInput = { mode, setMode, s, setS, d, setD, error, setError }
  const modeParams: IMode = { mode, setMode }
  const onHover = () => {}
  const inputHovered: IPressureWithType = { 
    s:0, d:0, type: hType, onHover: setHover, mode, setMode
  };

  const onBack = () => { setMode(Mode.Chart); setHover("");}
  const backParams = { onClick: onBack }
  return (
    <div className="blood-pressure-calc">
      <h2>BLOOD PRESSURE TOOL: STORYBOOK</h2>
      <div className="layout">
        <Lbox><BloodPressureInput {...inputBloodPressure} /></Lbox>
        <Rbox><Intro {...modeParams} /></Rbox>
      </div>

      <div className="layout">
        <Rbox><BloodPressureChart {...inputHovered} /></Rbox>
        <Rbox>
          {(!hType) ? <FindOutIntro {...modeParams} />: false}
          <BloodPressureDetails {...inputHovered} /><Back {...backParams} />
        </Rbox>
      </div>

      <div className="layout">
        <Rbox><BloodPressureChart  {...modeParams} onHover={onHover} type='Low' s={60} d={50} /></Rbox>
        <Rbox><BloodPressureDetails  {...modeParams} type='Low' s={60} d={50} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><BloodPressureChart  {...modeParams} onHover={onHover} type='Normal' s={100} d={70} /></Rbox>
        <Rbox><BloodPressureDetails  {...modeParams} type='Normal' s={100} d={70} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><BloodPressureChart  {...modeParams} onHover={onHover} type='Pre Hypertension' s={130} d={85} /></Rbox>
        <Rbox><BloodPressureDetails  {...modeParams} type='Pre Hypertension' s={130} d={85} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><BloodPressureChart  {...modeParams} onHover={onHover} type='High Stage 1' s={150} d={95} /></Rbox>
        <Rbox><BloodPressureDetails  {...modeParams} type='High Stage 1' s={150} d={95} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><BloodPressureChart  {...modeParams} onHover={onHover} type='High Stage 2' s={170} d={120} /></Rbox>
        <Rbox><BloodPressureDetails  {...modeParams} type='High Stage 2' s={170} d={120} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Lbox><LifestyleInput {...inputLifeStyle} /></Lbox>
        <Rbox><LifestyleFindOut value={getLifeStyleResult(lifestyle)} /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><LifestyleFindOut value='' /><Back {...backParams} /></Rbox>
        <Rbox><LifestyleFindOut value='Low' /><Back {...backParams} /></Rbox>
      </div>
      <div className="layout">
        <Rbox><LifestyleFindOut value='High/Low' /><Back {...backParams} /></Rbox>
        <Rbox><LifestyleFindOut value='High' /><Back {...backParams} /></Rbox>
      </div>
    </div>
  );
}

export const BloodPressure: React.FC<any> = () => {
  const [lifestyle, setLifestyle] = React.useState<Array<string>>([])
  const [mode, setMode] = React.useState(Mode.Input)
  const [hType, setHover] = React.useState('')
  const [error, setError] = React.useState('')
  const [s, setS] = React.useState(0)
  const [d, setD] = React.useState(0)
  const inputLifeStyle: ILifestyleInput = { lifestyle, setLifestyle, mode, setMode }
  const inputBloodPressure: IBloodPressureInput = { mode, setMode, s, setS, d, setD, error, setError }
  const inputBloodPressureType: IPressureWithType = { mode, setMode, s, d, type: getBloodPressureType(s, d), onHover: () => {} };
  const inputHovered: IPressureWithType = { mode, setMode, s:0, d:0, type: hType, onHover: setHover };

  // const inputNoPressure: IPressureWithType = { s:0, d:0, type: '', onHover: (t) => { console.log(t); } };
  const modeParams: IMode = { mode, setMode }
  const onBack = () => { 
    if (hType) {
      setMode(Mode.ChartHover); 
      setHover("");  
      return
    }
    const newMode = (mode === Mode.Chart || mode === Mode.ChartHover) ? Mode.Input : Mode.ChartHover;
    setMode(newMode); 
  };

  const backParams = { onClick: onBack }
  const isIntroMode = [Mode.Lifestyle, Mode.Answers, Mode.Chart, Mode.ChartHover].indexOf(mode) === -1;
  const isInputMode = [Mode.Lifestyle, Mode.Answers, Mode.Chart, Mode.ChartHover].indexOf(mode) === -1;
  return (
    <div className="blood-pressure-calc">
      <div className="layout">
        <Lbox>
          {(isInputMode) ? (<BloodPressureInput {...inputBloodPressure} />) : false}
          {(mode === Mode.ChartHover) ? (<BloodPressureChart {...inputHovered} />) : false}
          {(mode === Mode.Chart) ? (<BloodPressureChart {...inputBloodPressureType} />) : false}
          {(mode === Mode.Lifestyle || mode === Mode.Answers) ? (<LifestyleInput {...inputLifeStyle} />) : false}
        </Lbox>
        <Rbox>
          {isIntroMode ? <Intro {...modeParams} /> : false}
          {(mode === Mode.ChartHover && !hType) ? <FindOutIntro {...modeParams} /> : false}
          {(mode === Mode.ChartHover && hType) ? <BloodPressureDetails {...inputHovered} /> : false}
          {mode === Mode.Chart && !inputBloodPressureType.type ? <FindOutIntro {...modeParams} /> : false}
          {mode === Mode.Chart && inputBloodPressureType.type ? <BloodPressureDetails {...inputBloodPressureType} /> : false}
          {(mode === Mode.Answers) ? <LifestyleFindOut value={getLifeStyleResult(lifestyle)} /> : false}
          {(mode === Mode.Lifestyle) ? <LifestyleFindOut value={''} /> : false}
          {!isIntroMode ? <Back {...backParams} /> : false}
        </Rbox>
      </div>
    </div>
  );
}

